import React from 'react';
import { FC } from 'react';
import styles from "./app.module.css";
import Main from '../main/main';
import { Routes, Route } from 'react-router-dom';
import { TelegramIcon, VkIcon } from '../icons';

export const App: FC = () => {

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <h1 className={styles.title}>VEZDEPAY</h1>
        <div className={styles.header__socials}>
          <a href="https://vk.com/topic-227183820_53115795" target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>{VkIcon({ type: 'socials' })}</a>
          <a href="https://t.me/Vezdepaycom" target="_blank" rel="noreferrer" style={{ cursor: 'pointer', marginTop: '2px' }}>{TelegramIcon({ type: 'socials' })}</a>
        </div>
      </header>
      <Routes>
        <Route path={'/'} element={<Main />} />
        <Route path="*" element={<p className={styles.subtitle}>Ошибка 404. Нет такой страницы</p>} />
      </Routes>
    </div>
  );
}